.dapp-title {
  display: flex;
  align-items: center;
  gap: 8px;

  @media (max-width: 610px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }

  &__text {
    font-size: 30px;
    line-height: 34px;
    color: rgba(102,170,238,0.91);
    font-weight: bold;
  }

  &__badge {
    height: fit-content;
    padding: 0 8px;
    background-color: #ff4d4f;
    border-radius: 10px;
    margin-top: 8px;

    font-size: 12px;
    line-height: 20px;
    color: white;
  }
}
