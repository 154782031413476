.app {
  padding: 20px 40px;

  > header {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;
  }
}
