.ton-proof-demo {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin-top: 60px;

  h3.ant-typography {
    color: white;
    opacity: 0.8;
  }

  > div:nth-child(3) {
    width: 100%;

    span {
      word-break: break-word;
    }
  }

  &__error {
    color: rgba(102,170,238,0.91);
    font-size: 18px;
    line-height: 20px;
  }
}
